<template>
  <!-- Brand logo-->
  <b-link class="d-flex align-items-center brand-logo">
    <b-img
      :src="appLogoImage"
      alt="logo"
    />
    <h2 class="brand-text text-primary ml-1 mb-0">
      {{ $t(appName) }}
    </h2>
  </b-link>
  <!-- /Brand logo-->
</template>

<script>import {
  BLink, BImg,
} from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'

export default {
  name: 'Logo',
  components: {
    BLink,
    BImg,
  },
  computed: {
    appLogoImage() {
      const { appLogoImage } = $themeConfig.app
      return appLogoImage
    },
    appName() {
      const { appName } = $themeConfig.app
      return appName
    },
  },
}
</script>

<style lang="scss" scoped>
.brand-logo {
  display: flex;
}

.brand-logo img {
  max-width: 36px;
}
</style>
